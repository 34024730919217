<template>
  <v-dialog
    v-model="dialogDeletePalestra"
    @click:outside="$emit('update:dialogDeletePalestra', false)"
    @keydown.esc="$emit('update:dialogDeletePalestra', false)"
    max-width="450px"
  >
    <v-card>
      <v-card-title>
        <span class="text-body-2"
          >Tem certeza que deseja deletar essa palestra?</span
        >
      </v-card-title>
      <v-card-actions class="mt-4">
        <v-spacer></v-spacer>
        <v-btn
          color="save"
          @click="$emit('update:dialogDeletePalestra', false)"
          class="white--text"
        >
          Cancelar
        </v-btn>

        <v-btn color="error" @click="removePalestra(palestra_id)">
          <v-icon left>mdi-delete</v-icon>
          Deletar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { deletePalestra } from "@/api/admin/palestras.js";
export default {
  name: "DialogDeletePalestra",

  props: {
    dialogDeletePalestra: {
      type: Boolean,
      default: false,
    },
    palestra_id: {
      type: Number,
    },
  },
  data() {
    return {};
  },

  methods: {
    removePalestra(item) {
      deletePalestra(item)
        .then((response) => {
          if (response.status === 200) {
            this.$toast.success("Palestra deletada!");
            this.$emit("update:dialogDeletePalestra", false);
            this.$emit("fetch-palestras");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style></style>
